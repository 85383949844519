import { Directive, HostBinding } from '@angular/core';

import { ButtonBase } from './button-base';

/** Applies styles for the icon button. */
@Directive({
	selector: 'button[wndrcIconButton], a[wndrcIconButton]',
	standalone: true,
})
export class IconButtonDirective extends ButtonBase {

	@HostBinding('class')
	private get class(): string {
		return 'wndr-icon-button';
	}
}
